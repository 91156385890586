import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const Image = ({ src, alt, className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativeDirectory: {eq: "team"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 700) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = data.placeholderImage.edges.find(({ node }) => node.childImageSharp.fluid.originalName === src)
  if (file) {
    return <Img className={className} fluid={file.node.childImageSharp.fluid} />
  }
  return ''
}

const ContentTeamTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderEN = (
    <>
      <div className="row">
        <div className="col-12">
          <Image src="banner.jpg" className="img-fluid w-100" alt="Meet the team!" />
        </div>
        <div className="col-12">
          <h2 className="mt-4">You bet we are proud of our amazing team! Each one of us has their own expertise based on their personal preference. Being a passion for interior design, momlife or administration.</h2>
          <h4 className="mt-4">You will probably have chatted with one of these stars, either via email or in our store:</h4>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Valerie.jpg" className="img-fluid w-100" alt="Valerie" />
          <h3 className="mt-2">Valerie</h3>
          <ul>
            <li><b>Fouder</b> (focus on, well, everything)</li>
            <li><b>Also</b>: creative allrounder</li>
            <li><b>aka</b> girlboss</li>
            <li><b>Guilty pleasure</b>: Bridgerton "for the plot"</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/176-Mon-Dada" title="Mon Dada">Mon Dada</Link> & <Link to="/en/brands/148-O-My%20Bag" title="O My Bag">O My Bag</Link> & <Link to="/en/brands/77-Redo" title="REDO">REDO</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Stefan.jpg" className="img-fluid w-100" alt="Stefan" />
          <h3 className="mt-2">Stefan</h3>
          <ul>
            <li><b>Founder</b> (focus on systems, software and security)</li>
            <li><b>Also</b>: freelance solution architect</li>
            <li><b>aka</b> our personal IT-support</li>
            <li><b>Guilty pleasure</b>: Marvel and board games</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/49-HKLiving" title="HK living">HK Living</Link>, <Link to="/en/brands/70-Muuto" title="Muuto">Muuto</Link> & <Link to="/en/brands/15-Nicolas-Vahe" title="Nicolas Vahé">Nicolas Vahé</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Caroline.jpg" className="img-fluid w-100" alt="Caroline" />
          <h3 className="mt-2">Caroline</h3>
          <ul>
            <li><b>Shopmanager</b> (focus: interior decorator)</li>
            <li><b>Also</b>: interieor architect & owner of <a href="https://www.instagram.com/villa.fontaine/" title="Villa Fontaine" target="_blank">Villa Fontaine</a> (graphical
            ontwerp, tattoo artist, jewellery)</li>
            <li><b>aka</b> the zen-master</li>
            <li><b>Guilty pleasure</b>: reality-tv</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/23-ferm-LIVING" title="ferm LIVING">ferm LIVING</Link> & <Link to="/en/brands/49-HKLiving" title="HK living">HK Living</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Els.jpg" className="img-fluid w-100 greyscale" alt="Els" />
          <h3 className="mt-2">Els</h3>
          <ul>
            <li><b>Administrative centipede</b> (focus: webshop and catalog)</li>
            <li><b>Also</b>: <a href="https://www.mintdesign.be/blushphotography" title="Mint design" target="_blank">part-time photographer and graphical designer</a> & mother of
            Rens and Wolf</li>
            <li><b>aka</b> our trendspotter</li>
            <li><b>Guilty pleasure</b>: Gerard Joling on repeat while editing photo's</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/70-Muuto" title="Muuto">Muuto</Link>, <Link to="/en/brands/23-ferm-LIVING" title="ferm LIVING">ferm LIVING</Link> & <Link to="/en/brands/172-Bobo-Choses" title="Bobo Choses">Bobo Choses</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Michele.jpg" className="img-fluid w-100 greyscale" alt="Michele" />
          <h3 className="mt-2">Michele</h3>
          <ul>
            <li><b>Saleswoman</b> (focus: products and prices)</li>
            <li><b>Also</b>: an unstoppable senior force</li>
            <li><b>aka</b> our Marie Kondo</li>
            <li><b>Guilty pleasure</b>: chocolates from Puur</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/50-HAY" title="HAY">HAY</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Bas.jpg" className="img-fluid w-100" alt="Bas" />
          <h3 className="mt-2">Bas</h3>
          <ul>
            <li><b>Office dog</b> (focus: watching over the store from the kitchen)</li>
            <li><b>Also</b>: chasing around in the forest</li>
            <li><b>aka</b> heart & petty thief</li>
            <li><b>Guilty pleasure</b>: chasing bunnies</li>
            <li><b>Favorite brands</b>: <Link to="/en/brands/84-Jellycat" title="Jellycat">Jellycat</Link> </li>
          </ul>
        </div>
      </div>
    </>
  )

  const renderNL = (
    <>
      <div className="row">
        <div className="col-12">
          <Image src="banner.jpg" className="img-fluid w-100" alt="Meet the team!" />
        </div>
        <div className="col-12">
          <h2 className="mt-4">En of we trots zijn op ons straf team! Elk van ons heeft zijn specialisatie op basis van een voorliefde. Of dat nu een passie voor interieur, momlife of administratie is.</h2>
          <h4 className="mt-4">Met deze toppers heb je vast al eens gemaild of een praatje gemaakt in de winkel:</h4>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Valerie.jpg" className="img-fluid w-100" alt="Valerie" />
          <h3 className="mt-2">Valerie</h3>
          <ul>
            <li><b>Oprichtster</b> (focus op, nu ja, alles)</li>
            <li><b>Daarnaast</b>: allround creatieveling</li>
            <li><b>aka</b> girlboss</li>
            <li><b>Guilty pleasure</b>: Bridgerton "voor het verhaal"</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/176-Mon-Dada" title="Mon Dada">Mon Dada</Link> & <Link to="/nl/merken/148-O-My%20Bag" title="O My Bag">O My Bag</Link> & <Link to="/nl/merken/77-Redo" title="REDO">REDO</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Stefan.jpg" className="img-fluid w-100" alt="Stefan" />
          <h3 className="mt-2">Stefan</h3>
          <ul>
            <li><b>Oprichter</b> (focus op systemen, software en security)</li>
            <li><b>Daarnaast</b>: freelance solution architect</li>
            <li><b>aka</b> onze persoonlijke IT-dienst</li>
            <li><b>Guilty pleasure</b>: Marvel en board games</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/49-HKLiving" title="HK living">HK Living</Link>, <Link to="/nl/merken/70-Muuto" title="Muuto">Muuto</Link> & <Link to="/nl/merken/15-Nicolas-Vahe" title="Nicolas Vahé">Nicolas Vahé</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Caroline.jpg" className="img-fluid w-100" alt="Caroline" />
          <h3 className="mt-2">Caroline</h3>
          <ul>
            <li><b>Shopmanager</b> (focus op interieuradvies)</li>
            <li><b>Daarnaast</b>: interieurarchitecte & eigenares van <a href="https://www.instagram.com/villa.fontaine/" title="Villa Fontaine" target="_blank">Villa Fontaine</a> (grafisch
            ontwerp, tattoo artiest, juwelen)</li>
            <li><b>aka</b> de zen-master</li>
            <li><b>Guilty pleasure</b>: reality-tv</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/23-ferm-LIVING" title="ferm LIVING">ferm LIVING</Link> & <Link to="/nl/merken/49-HKLiving" title="HK living">HK Living</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Els.jpg" className="img-fluid w-100" alt="Els" />
          <h3 className="mt-2">Els</h3>
          <ul>
            <li><b>Administratieve duizendpoot</b> (focus op webshop en assortiment)</li>
            <li><b>Daarnaast</b>: <a href="https://www.mintdesign.be/blushphotography" title="Mint design" target="_blank">fotografe en grafisch ontwerper in bijberoep</a> & mama van
            Rens en Wolf</li>
            <li><b>aka</b> de trendspotter</li>
            <li><b>Guilty pleasure</b>: Gerard Joling op repeat zetten tijdens het editen van foto's</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/70-Muuto" title="Muuto">Muuto</Link>, <Link to="/nl/merken/23-ferm-LIVING" title="ferm LIVING">ferm LIVING</Link> & <Link to="/nl/merken/172-Bobo-Choses" title="Bobo Choses">Bobo Choses</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Michele.jpg" className="img-fluid w-100" alt="Michele" />
          <h3 className="mt-2">Michele</h3>
          <ul>
            <li><b>Verkoopster</b> (focus op producten en prijzen)</li>
            <li><b>Daarnaast</b>: een niet te stoppen zestigplusser</li>
            <li><b>aka</b> onze Marie Kondo</li>
            <li><b>Guilty pleasure</b>: pralines van chocolaterie Puur</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/50-HAY" title="HAY">HAY</Link></li>
          </ul>
        </div>

        <div className="col-sm-6 col-lg-3 mb-3">
          <Image src="Bas.jpg" className="img-fluid w-100" alt="Bas" />
          <h3 className="mt-2">Bas</h3>
          <ul>
            <li><b>Office dog</b> (focus op de winkel in de gaten houden vanuit de keuken)</li>
            <li><b>Daarnaast</b>: rondrennen in het bos</li>
            <li><b>aka</b> harten- en kruimeldief</li>
            <li><b>Guilty pleasure</b>: op konijnen jagen</li>
            <li><b>Favoriete merken</b>: <Link to="/nl/merken/84-Jellycat" title="Jellycat">Jellycat</Link> </li>
          </ul>
        </div>
      </div>
    </>
  )


  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentTeamTemplate
